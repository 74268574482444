import { Component } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { Router } from '@angular/router';
import { SocialUser } from "@abacritt/angularx-social-login";
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
	selector: 'app-alpha-info',
	templateUrl: './alpha-info.component.html',
	styleUrls: ['./alpha-info.component.css']
})
export class AlphaInfoComponent {

	constructor(private socialAuthService: SocialAuthService,
		private router: Router, public auth: AuthenticationService) {
	}

	GoogleLoginProvider = GoogleLoginProvider;

	error: string = '';

	ngOnInit() {
		this.auth.signIn().subscribe(() => {
		}, (err: any) => {
			this.error = 'Your token has expired, please reload the page and sign in again';
			this.navigateHome();
		});
	}

	navigateHome() {
		this.router.navigate(['home']);
	}
}
