import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LoadService {

	constructor() { }

	loading: number = 0;
	
	public incLoading() {
		this.loading++;
	}
	
	public decLoading() {
		this.loading--;
	}
	
	public getLoading(): number {
		return this.loading;
	}
}
