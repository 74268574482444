import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JediService } from '../../services/jedi.service';
import { SocialUser } from "@abacritt/angularx-social-login";
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
	selector: 'app-haus-header',
	templateUrl: './haus-header.component.html',
	styleUrls: ['./haus-header.component.css']
})
export class HausHeaderComponent {

	userHead: SocialUser;
	showUserMenu: boolean = false;

	@Input()
	userName?: string;

	@Input()
	photoUrl?: string;

	@Input()
	userId?: string;

	@Input()
	hideProfile?: string;

	constructor(
		private route: ActivatedRoute, private socialAuthService: SocialAuthService, private router: Router, public auth: AuthenticationService
	) {
	}

	ngOnInit() {
	}

	signOut(): void {
		this.auth.signOut();
		this.toggleUserMenu();
		if (this.router.url === '/home') {
			location.reload();
		} else {
			this.navigateHome();
		}
	}

	public toggleUserMenu() {
		this.showUserMenu = !this.showUserMenu;
	}

	public navigateHome() {
		this.router.navigateByUrl('/', { skipLocationChange: false })
			.then(() => this.router.navigate(['home']));
	}

	public navigateAlpha() {
		this.router.navigate(['alpha']);
	}

	public navigatePrivacy() {
		this.router.navigate(['privacy']);
	}

	public navigateProfile() {
		this.router.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate(['profile', this.userId]));
	}

	public loggedIn(): boolean {
		return (this.userName != null && this.userName.length > 0)
	}

}
