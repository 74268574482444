import { Component } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { Router, ActivatedRoute } from '@angular/router';
import { SocialUser } from "@abacritt/angularx-social-login";
import { JediService } from '../shared/services/jedi.service';
import { Awss3Service } from '../shared/services/awss3.service';
import { LoadService } from '../shared/services/load.service';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
	selector: 'app-profile-edit',
	templateUrl: './group-manage.component.html',
	styleUrls: ['./group-manage.component.css']
})
export class GroupManageComponent {

	constructor(private socialAuthService: SocialAuthService,
		private router: Router, private service: JediService, private awss3: Awss3Service, private route: ActivatedRoute, public auth: AuthenticationService, private load: LoadService) {
	}

	public loading(): boolean {
		return this.load.getLoading() > 0;
	}

	loggedIn: boolean;
	namePresent: boolean = true;
	name: string = '';
	id: number;
	desc: string = '';
	motd: string = '';
	imagetype: string;
	url: any;
	imageFile: any;
	group: any = { photoUrl: '../../assets/defaultGroup.png' };
	authorized: boolean = true;
	ogName = '';
	ogDesc = '';
	ogMotd = '';
	imgChanged = false;
	file: any;
	deletePopup = false;

	error: string = '';

	ngOnInit() {
		this.load.incLoading();
		this.auth.signIn();
		this.route.params.subscribe((params: any) => {
			this.id = params['id'];
			if (this.auth.getToken() !== null) {
				this.service.getGroup(this.auth.getToken(), this.id).subscribe((response: any) => {
					this.group = response;
					this.name = response.name
					this.ogName = response.name;
					this.desc = response.desc === null ? '' : response.desc;
					this.ogDesc = response.desc === null ? '' : response.desc;
					this.motd = response.motd
					this.ogMotd = response.motd;
					this.loadImage();
					if (response.imagetype !== null) {
						this.imagetype = response.imagetype;
					}
					this.load.decLoading();
				}, (err: any) => {
					this.error = 'Error loading group';
					this.load.decLoading();
				});
			}
		});
	}

	validateForm(): string {
		const uint8arr = new Uint8Array(this.file)

		const len = 4
		if (uint8arr.length >= len) {
			let signatureArr = new Array(len)
			for (let i = 0; i < len; i++)
				signatureArr[i] = (new Uint8Array(this.file))[i].toString(16)
			const signature = signatureArr.join('').toUpperCase();

			switch (signature) {
				case '89504E47':
					return 'png'
				case '47494638':
					return 'gif'
				case 'FFD8FFDB':
				case 'FFD8FFE0':
				case 'FFD8FFE2':
					return 'jpeg'
				case '4d4d002a':
				case '4d492a00':
					return 'tiff'
				default:
					return ''
			}
		}
		return '';
	}

	public changed(): boolean {
		return this.name !== this.ogName || this.desc !== this.ogDesc || this.motd !== this.ogMotd || this.imgChanged;
	}

	quitPopup() {
		this.deletePopup = false;
	}

	showPopup() {
		this.deletePopup = true;
	}

	confirmDelete() {
		this.load.incLoading();
		this.service.deleteGroup(this.auth.getToken(), this.id).subscribe(() => {
			this.awss3.deleteGroupPhoto(this.id.toString().trim() + '.' + this.imagetype).subscribe((response: any) => {
				this.navigateHome();
			});
			this.load.decLoading();

		}, (err: any) => {
			this.error = 'Error deleting group';
			this.load.decLoading();
		});
	}

	public editGroup() {
		if (this.imgChanged) {
			this.imagetype = this.validateForm();
			this.namePresent = this.imagetype.length > 0;
		}
		if (this.namePresent) {
			let token = localStorage.getItem('idToken');
			if (token !== null) {
				this.service.editGroup(token, this.id, this.name.trim(), this.desc.trim(), this.motd.trim(), this.imagetype).subscribe((response: any) => {
					if (this.imgChanged) {
						this.awss3.deleteGroupPhoto(this.id.toString().trim() + '.' + this.group.imagetype).subscribe((response: any) => {
							this.awss3.setGroupPhoto(this.id.toString().trim() + '.' + this.imagetype, this.file).subscribe((response: any) => {
								this.router.navigate(['group', this.id]);
							});
						});
					} else {
						this.router.navigate(['group', this.id]);
					}
				});
			}
		}
	}

	private loadImage() {
		this.load.incLoading();
		if (this.group.imagetype == null) {
			this.url = '../../assets/defaultGroup.png';
			this.load.decLoading();
		} else {
			let imageName = this.group.id + '.' + this.group.imagetype;
			this.awss3.getGroupPhoto(imageName).subscribe((blob: any) => {
				this.createImageMapFromBlob(blob, this.group.id).then(() => {
					this.load.decLoading();
				});
			});
		}
	}

	async createImageMapFromBlob(image: Blob, groupId: string) {
		let reader = new FileReader();
		reader.addEventListener("load", () => {
			this.url = reader.result;
		}, false);

		if (image) {
			reader.readAsDataURL(image);
		}
	}

	dataUrlToBlob(dataUrl: string): Blob {
		// Convert base64 to binary
		const binaryString = atob(dataUrl.split(',')[1]);

		// Create a typed array from the binary string
		const arrayBuffer = new ArrayBuffer(binaryString.length);
		const uint8Array = new Uint8Array(arrayBuffer);

		for (let i = 0; i < binaryString.length; i++) {
			uint8Array[i] = binaryString.charCodeAt(i);
		}

		// Create a Blob from the typed array
		return new Blob([uint8Array], { type: dataUrl.split(',')[0].split(':')[1].split(';')[0] });
	}

	onSelectFile(event: any) {
		this.namePresent = true;
		if (event.target.files && event.target.files[0]) {
			var reader = new FileReader();

			reader.readAsArrayBuffer(event.target.files[0]); // read file as data url

			reader.onload = (event) => { // called once readAsArrayBuffer is completed
				this.file = event.target?.result;
				this.imgChanged = true;
			}

			var reader2 = new FileReader();

			reader2.readAsDataURL(event.target.files[0]); // read file as data url

			reader2.onloadend = (event) => { // called once readAsArrayBuffer is completed
				this.url = event.target?.result;
				this.imgChanged = true;
			}
		}
	}

	arrayBufferToBase64(buffer: any): string {
		var binary = '';
		var bytes = new Uint8Array(buffer);
		var len = bytes.byteLength;
		for (var i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	}

	cancel() {
		this.router.navigate(['group', this.id]);
	}

	navigateHome() {
		this.router.navigate(['home']);
	}


}
