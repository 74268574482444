import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class JediService {

	baseUrl = 'https://jediservices.herokuapp.com/api/';

	constructor(private http: HttpClient) { }

	public ping(): Observable<any> {
		const url = this.baseUrl;

		return this.http.get(url);
	}

	public login(token: string): Observable<any> {
		const url = this.baseUrl + 'signin';

		return this.http.post(url, token);
	}

	public getUserGroups(token: string): Observable<any> {
		const url = this.baseUrl + 'userGroups';

		return this.http.post(url, token);
	}

	public getGroupUsers(token: string, id: number): Observable<any> {
		const url = this.baseUrl + 'groupUsers';

		const request = {
			item: id,
			token: token
		}

		return this.http.post(url, request);
	}

	public createGroup(token: string, name: string, desc: string,  motd: string, owner: string): Observable<any> {
		const url = this.baseUrl + 'createGroup';

		const item = {
			name: name,
			desc: desc,
			motd: motd,
			owner: owner
		}

		const request = {
			item: item,
			token: token
		}

		return this.http.post(url, request);
	}

	public getGroup(token: string, id: number): Observable<any> {
		const url = this.baseUrl + 'group';

		const request = {
			item: id,
			token: token
		}

		return this.http.post(url, request);
	}

	public deleteGroup(token: string, id: number): Observable<any> {
		const url = this.baseUrl + 'deleteGroup';

		const request = {
			item: id,
			token: token
		}

		return this.http.post(url, request);
	}

	public getInviteGroup(token: string, id: number): Observable<any> {
		const url = this.baseUrl + 'getInviteGroup';

		const request = {
			item: id,
			token: token
		}

		return this.http.post(url, request);
	}

	public useInvite(token: string, id: number): Observable<any> {
		const url = this.baseUrl + 'useInvite';

		const request = {
			item: id,
			token: token
		}

		return this.http.post(url, request);
	}

	public leaveGroup(token: string, id: number): Observable<any> {
		const url = this.baseUrl + 'leaveGroup';

		const request = {
			item: id,
			token: token
		}

		return this.http.post(url, request);
	}

	public createInvite(token: string, groupId: number): Observable<any> {
		const url = this.baseUrl + 'createInvite';

		const invite = {
			groupId: groupId,
			role: 0
		}

		const request = {
			item: invite,
			token: token
		}

		return this.http.post(url, request);
	}

	public editGroup(token: string, id: number, name: string, desc: string, motd: string, imagetype: string): Observable<any> {
		const url = this.baseUrl + 'edit/group';

		const group = {
			id: id,
			name: name,
			desc: desc,
			motd: motd,
			imagetype: imagetype
		}

		const request = {
			item: group,
			token: token
		}

		return this.http.post(url, request);
	}

	public getProfile(token: string, id: string): Observable<any> {
		const url = this.baseUrl + 'user';

		const request = {
			item: id,
			token: token
		}

		return this.http.post(url, request);
	}

	public editProfile(token: string, id: string, name: string, bio: string): Observable<any> {
		const url = this.baseUrl + 'edit/user';

		const user = {
			id: id,
			name: name,
			bio: bio
		}

		const request = {
			item: user,
			token: token
		}

		return this.http.post(url, request);
	}

	public getTodos(token: string, userid: string): Observable<any> {
		const url = this.baseUrl + 'todo';

		const request = {
			item: userid,
			token: token
		}

		return this.http.post(url, request);
	}

	public deleteTodo(token: string, userid: string, id: number): Observable<any> {
		const url = this.baseUrl + 'delete/todo';

		const todo = {
			userid: userid,
			id: id
		}

		const request = {
			item: todo,
			token: token
		}

		return this.http.post(url, request);
	}

	public processTodos(token: string, todos: any[]): Observable<any> {
		const url = this.baseUrl + 'processTodos';

		const request = {
			item: todos,
			token: token
		}

		return this.http.post(url, request);
	}

  public getGroupEvents(token: string, groupid: number): Observable<any> {
    const url = this.baseUrl + 'groupEvents';

    const request = {
      item: groupid,
      token: token
    }

    return this.http.post(url, request);
  }

  public createEvent(token: string, groupId: number, name: string, description: string, startTime: Date, endTime: Date, location: string): Observable<any> {
    const url = this.baseUrl + 'events';

    const event = {
      name: name,
      description: description,
      startTime: startTime,
      endTime: endTime,
      location: location
    }

    const request = {
      item: event,
      token: token
    }

    return this.http.post(url, request, {params: new HttpParams().set('groupId', groupId)});
  }

  deleteEvent(eventId: number, token: string): Observable<any> {
    return this.http.delete(this.baseUrl + `events/${eventId}`, {params: new HttpParams().set('token', token)});
  }

  getEvent(eventId: number, token: string): Observable<any> {
    const url = this.baseUrl + 'getEvent';

    const request = {
      item: eventId,
      token: token
    }

    return this.http.post(url, request);
  }

  updateEvent(eventId: number, name: string, description: string, startTime: Date, endTime: Date, location: string, token: string): Observable<any> {
    const url = this.baseUrl + 'updateEvent';

    const event = {
      id: eventId,
      name: name,
      description: description,
      startTime: startTime,
      endTime: endTime,
      location: location
    }

    const request = {
      item: event,
      token: token
    }

    return this.http.post(url, request);
  }
}
