import { Component } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { Router } from '@angular/router';
import { JediService } from '../shared/services/jedi.service';
import { Awss3Service } from '../shared/services/awss3.service';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
	selector: 'app-create-group',
	templateUrl: './create-group.component.html',
	styleUrls: ['./create-group.component.css']
})
export class CreateGroupComponent {

	constructor(private socialAuthService: SocialAuthService,
		private router: Router, private service: JediService, private awss3: Awss3Service, public auth: AuthenticationService) {
	}

	name: string = '';
	desc: string = '';
	motd: string = '';
	namePresent = true;
	descPresent = true;

	error: string = '';

	ngOnInit() {
		this.auth.signIn().subscribe(() => {
		}, (err: any) => {
			this.error = 'Your token has expired, please reload the page and sign in again';
			this.navigateHome();
		});
	}

	private validForm() {
		this.namePresent = this.name?.trim().length > 0 && this.name?.trim().length < 25;
		this.descPresent = this.desc?.trim().length > 0 && this.desc?.trim().length < 256;

		return this.namePresent && this.descPresent;
	}

	public valid() {
		return this.name?.trim().length > 0 && this.name?.trim().length < 25 && this.desc?.trim().length > 0 && this.desc?.trim().length < 256;
	}

	private normalizeDesc() {
		this.desc = this.desc?.trim().replace(/(\r\n|\r|\n){2,}/g, '$1\n');
	}

	private normalizeMotd() {
		this.motd = this.motd?.trim().replace(/(\r\n|\r|\n){2,}/g, '$1\n');
	}

	navigateHome() {
		this.router.navigate(['home']);
	}

	createGroup() {
		if (this.validForm()) {
			if (this.auth.getToken().length < 1 || this.auth.getUserId() === null) {
				this.auth.signIn().subscribe(() => {
				}, (err: any) => {
					this.error = 'Your token has expired, please reload the page and sign in again';
					this.navigateHome();
				});
			} else {
				this.normalizeDesc();
				this.normalizeMotd();
				this.service.createGroup(this.auth.getToken(), this.name.trim(), this.desc, this.motd, this.auth.getUserId()).subscribe((response: any) => {
					this.navigateHome();
				}, (err: any) => {
					this.error = err;
				});
			}
		}
	}

	cancel() {
		this.router.navigate(['home']);
	}

}
