import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HausHeaderComponent } from './shared/components/haus-header/haus-header.component';
import { JediService } from './shared/services/jedi.service';
import { HttpClientModule } from '@angular/common/http';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { GoogleLoginProvider, SocialLoginModule, SocialAuthServiceConfig, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { CreateGroupComponent } from './create-group/create-group.component';
import { FormsModule } from '@angular/forms';
import { AlphaInfoComponent } from './alpha-info/alpha-info.component';
import { GroupViewComponent } from './group-view/group-view.component';
import { ProfileViewComponent } from './profile-view/profile-view.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { TodoManageComponent } from './todo-manage/todo-manage.component';
import { GroupManageComponent } from './group-manage/group-manage.component';
import { InviteComponent } from './invite/invite.component';
import { CreateInviteComponent } from './create-invite/create-invite.component';
import { CreateEventComponent } from './create-event/create-event.component';
import { EventEditComponent } from './event-edit/event-edit.component';
import { SignInComponent } from './sign-in/sign-in.component';

@NgModule({
	declarations: [
		AppComponent,
		HomePageComponent,
		HausHeaderComponent,
		PrivacyNoticeComponent,
		CreateGroupComponent,
		AlphaInfoComponent,
		GroupViewComponent,
		ProfileViewComponent,
		ProfileEditComponent,
  TodoManageComponent,
  GroupManageComponent,
  InviteComponent,
  CreateInviteComponent,
  CreateEventComponent,
  EventEditComponent,
  SignInComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		SocialLoginModule,
		GoogleSigninButtonModule,
		FormsModule,
	],
	providers: [
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: true,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(
							'961805937236-qars9onbmaoe83226r4e38s5gus0d38g.apps.googleusercontent.com', {oneTapEnabled:false, prompt:'consent'}
						)
					}
				],
				onError: (err) => {
					console.error(err);
				}
			} as SocialAuthServiceConfig,
		},
		JediService,
		Title
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
