import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { AlphaInfoComponent } from './alpha-info/alpha-info.component';
import { GroupViewComponent } from './group-view/group-view.component';
import { ProfileViewComponent } from './profile-view/profile-view.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { TodoManageComponent } from './todo-manage/todo-manage.component';
import { GroupManageComponent } from './group-manage/group-manage.component';
import { InviteComponent } from './invite/invite.component';
import { CreateInviteComponent } from './create-invite/create-invite.component';
import { CreateEventComponent } from './create-event/create-event.component';
import { EventEditComponent } from './event-edit/event-edit.component';
import { SignInComponent } from './sign-in/sign-in.component';

const routes: Routes = [
	{ path: 'home', component: HomePageComponent, title: 'JediHaus' },
  { path: 'signIn', component: SignInComponent, title: 'JediHaus Sign-in' },
	{ path: 'privacy', component: PrivacyNoticeComponent, title: 'JediHaus Privacy' },
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: 'createGroup', component: CreateGroupComponent, title: 'Create Group' },
	{ path: 'alpha', component: AlphaInfoComponent, title: 'Alpha Info' },
	{ path: 'group/:id', component: GroupViewComponent, title: 'JediHaus' },
	{ path: 'profile/:id', component: ProfileViewComponent, title: 'JediHaus' },
	{ path: 'profile/edit/:id', component: ProfileEditComponent, title: 'Edit Profile' },
	{ path: 'todos/edit/:id', component: TodoManageComponent, title: 'Manage To-do List' },
	{ path: 'group/edit/:id', component: GroupManageComponent, title: 'Manage Group' },
	{ path: 'invite/:id', component: InviteComponent, title: 'JediHaus' },
	{ path: 'createInvite/:id', component: CreateInviteComponent, title: 'JediHaus' },
  { path: 'createEvent/:id', component: CreateEventComponent, title: 'Create Event' },
  { path: 'editEvent/:groupId/:id', component: EventEditComponent, title: 'Edit Event' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
