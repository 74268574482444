import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SocialUser } from "@abacritt/angularx-social-login";
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent {
  constructor(
    private route: ActivatedRoute, private socialAuthService: SocialAuthService, private router: Router, public auth: AuthenticationService
  ) {
    auth: auth;
  }

  navigateHome() {
    this.router.navigate(['home']);
  }
  
  ngOnInit() {
    this.auth.signIn().subscribe(() => {
      if (!this.auth.getToken()) {

      } else {
      }
    }, (err: any) => {
    });
  }
}
