import { Component } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	animations: [
		trigger(
			'enterAnimation', [
			transition(':enter', [
				style({ transform: 'translateX(100%)', opacity: 0 }),
				animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))
			]),
			transition(':leave', [
				style({ transform: 'translateX(0)', opacity: 1 }),
				animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 }))
			])
		]
		)
	],
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	title = 'jedi-haus';
}
