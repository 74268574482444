import { Component } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { Router, ActivatedRoute } from '@angular/router';
import { SocialUser } from "@abacritt/angularx-social-login";
import { AuthenticationService } from '../shared/services/authentication.service';
import { JediService } from '../shared/services/jedi.service';
import { LoadService } from '../shared/services/load.service';

@Component({
	selector: 'app-create-invite',
	templateUrl: './create-invite.component.html',
	styleUrls: ['./create-invite.component.css']
})
export class CreateInviteComponent {

	constructor(private socialAuthService: SocialAuthService,
		private router: Router, public auth: AuthenticationService, private service: JediService, private route: ActivatedRoute, private load: LoadService) {
	}

	GoogleLoginProvider = GoogleLoginProvider;

	error: string = '';
	inviteCode: string = '';
	id: number;

	ngOnInit() {
		this.auth.signIn().subscribe(() => {
			this.route.params.subscribe((params: any) => {
				this.id = params['id']
				this.service.createInvite(this.auth.getToken(), this.id).subscribe((code: any) => {
					this.inviteCode = code;
				}, (err: any) => {
					this.error = 'Error creating invite';
				});
			}, (err: any) => {
				this.error = 'Your token has expired, please reload the page and sign in again';
				this.navigateHome();
			});
		});
	}

	copyText() {
		navigator.clipboard.writeText('https://www.jedi.haus/invite/' + this.inviteCode);

		this.error = 'Link copied!'
		setTimeout(() => {
			this.error = '';
		}, 1000);
	}

	navigateHome() {
		this.router.navigate(['home']);
	}
}
