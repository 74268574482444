<html>
<div class="errors" *ngIf="this.error?.length !== 0">
	<p class="errors">{{ this.error }}</p>
	<button class="noPad" (click)="this.error=''">X</button>
</div>
<body>
	<div class="loading" [hidden]="!this.loading()">
		<img id='loadImg' src='../../assets/loading.png'>
	</div>
	<app-haus-header userName="{{ this.auth.getName() }}"
		photoUrl="{{ this.auth.getPhotoUrl() }}" userId="{{ this.auth.getUserId() }}"></app-haus-header>
	<div class="content">
		<div *ngIf="!this.authorized">
			<h1>You cannot edit another user's profile</h1>
			<h4>Sorry.</h4>
		</div>
		<div *ngIf="this.authorized">
			<div class="panel">
				<form (submit)="editProfile()">
					<img class="headImg" id='groupImg'
						src='../../assets/editProfile.png'> <br> <label
						class="light" for="name">Name *</label><br> <input
						maxlength="24" [(ngModel)]="name" type="text" id="name"
						name="name">
					<p class="validation" *ngIf="!namePresent">Required Field</p>
					<br>
					<br *ngIf="namePresent"> <label class="light" for="bio">Bio</label><br>
					<textarea [(ngModel)]="bio" type="text" maxlength="255"
						oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
						id="bioo" name="bio"></textarea>
					<br>
					<br>
					<button type="submit" [class.unclickable]="!this.changed()">Submit
						Changes</button>
					<button style="margin-left: 15px" (click)="cancel()">Cancel</button>
				</form>
			</div>
		</div>
	</div>
</body>
</html>

