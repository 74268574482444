import { Component } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { Router, ActivatedRoute } from '@angular/router';
import { SocialUser } from "@abacritt/angularx-social-login";
import { AuthenticationService } from '../shared/services/authentication.service';
import { JediService } from '../shared/services/jedi.service';
import { LoadService } from '../shared/services/load.service';
import { Title } from '@angular/platform-browser';
import { Awss3Service } from '../shared/services/awss3.service';

@Component({
	selector: 'app-invite',
	templateUrl: './invite.component.html',
	styleUrls: ['./invite.component.css']
})
export class InviteComponent {

	constructor(private socialAuthService: SocialAuthService,
		private router: Router, public auth: AuthenticationService, private service: JediService, private route: ActivatedRoute, private load: LoadService, private title: Title, private awss3: Awss3Service) {
	}

	GoogleLoginProvider = GoogleLoginProvider;

	error: string = '';
	id: number;
	group: any;
	groupImage: any;

	ngOnInit() {
		this.auth.signIn().subscribe(() => {
			if (!this.auth.getToken()) {

			} else {
				this.route.params.subscribe((params: any) => {
					this.id = params['id']

					this.load.incLoading();
					this.service.getInviteGroup(this.auth.getToken(), this.id).subscribe((response: any) => {
						this.group = response;
						this.title.setTitle('Join ' + response.name);
						this.loadImage();
					}, (err: any) => {
						this.error = 'Error loading invite group';
						this.navigateHome();
					}).add(() => {
						this.load.decLoading();
					});
				}, (err: any) => {
					this.error = 'Error loading invite';
					this.navigateHome();
				});
			}
		}, (err: any) => {
			this.error = 'Your token has expired, please sign in and reload the page';
		});
	}

	navigateHome() {
		this.router.navigate(['home']);
	}

	private loadImage() {
		if (this.group.imagetype == null) {
			this.groupImage = '../../assets/defaultGroup.png';
		} else {
			let imageName = this.group.id + '.' + this.group.imagetype;
			this.load.incLoading();
			this.awss3.getGroupPhoto(imageName).subscribe((blob: any) => {
				this.createImageMapFromBlob(blob, this.group.id).then(() => {
					this.load.decLoading();
				});
			});
		}
	}

	async createImageMapFromBlob(image: Blob, groupId: string) {
		let reader = new FileReader();
		reader.addEventListener("load", () => {
			this.groupImage = reader.result;
		}, false);

		if (image) {
			reader.readAsDataURL(image);
		}
	}

	joinGroup() {
		this.load.incLoading();
		this.service.useInvite(this.auth.getToken(), this.id).subscribe(() => {
			this.navigateHome();
		}, (err: any) => {
			this.error = 'Error joining group. Invite expired, invite inteded for another user, or user is already a group member';
		}).add(() => {
			this.load.decLoading();
		});
	}
}
