<html>
<div class="errors" *ngIf="this.error?.length !== 0">
	<p class="errors">{{ this.error }}</p>
	<button class="noPad" (click)="this.error=''">X</button>
</div>
<body>
	<app-haus-header userName="{{ this.auth.getName() }}"
		photoUrl="{{ this.auth.getPhotoUrl() }}"
		userId="{{ this.auth.getUserId() }}"></app-haus-header>
	<div class="content">
		<h4>Current Version</h4>
		<p>1.1.2-alpha</p>
		<p>Major Version Release: Aug 15 2024</p>
		<h2>General Info:</h2>
		<p>Mobile not recommended, although effort was put in to make it
			usable. App someday? Maybe after beta.</p>
		<br>
		<a style="font-size:16px; color: #83aeb5" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSepJoWtlPnBfhvkHWNnMalD-MobZZlPszHuUkoTCvCMox_rwA/viewform?usp=pp_url">Click Here To Report a Bug</a>
		<br><br>
		<h2>Known Bugs:</h2>
		<ul>
			<li>!! Sign in can be wonky, addressed???</li>
			<li>! Loading screens disappear before images fully loaded and tables populated</li>
		</ul>
		<br>
		<h2>Future Alpha Content:</h2>
		<ul>
			<li>Announcements</li>
			<li>Votes</li>
			<li>Trips</li>
			<li>Event RSVP</li>
			<li>Edit role permissions</li>
			<li>Edit role names</li>
			<li>Manage group members/kick/ban</li>
			<li>Editable user profile pictures</li>
			<li>Edit home page group order</li>
			<li>More extensive Todo editing</li>
			<li>UI improvements</li>
		</ul>
		<h2>Planned Beta Content:</h2>
		<ul>
			<li>Commemorative Badges</li>
			<li>Interpersonal bet tracking</li>
			<li>Group member availability calendar</li>
			<li>Group message board</li>
			<li>Public group listing</li>
			<li>Direct messaging</li>
			<li>Group "lore" log</li>
			<li>More extensive breadcrumbs</li>
			<li>UI improvements</li>
			<li>Extensive use-flow rework</li>
		</ul>
		<h2>Possible Full Release Content:</h2>
		<ul>
			<li>Website name change</li>
			<li>Mobile application</li>
			<li>UI facelift</li>
		</ul>
		<br>
		<h1>Major Versions</h1>
    <h3>1.1.0-alpha (current)</h3>
    <p>
      <i>Aug 15 2024: Event Horizon</i>
    </p>
    <h4>New Features</h4>
    <ul>
      <li>Events</li>
    </ul>
<!--    <h4>Improvements</h4>-->
<!--    <ul>-->
<!--      <li>None :)</li>-->
<!--    </ul>-->
    <br>
		<h3>1.0.0-alpha</h3>
		<p>
			<i>Dec 27 2023: Premier, skeletal, and functional release</i>
		</p>
		<h4>New Features</h4>
		<ul>
			<li>Groups</li>
			<li>Group Invites</li>
			<li>User Profiles</li>
			<li>Todo Lists</li>
		</ul>
		<h4>Improvements</h4>
		<ul>
			<li>Sign In Token Cached</li>
			<li>UI</li>
		</ul>
		<br>
		<h3>0.0.0-prealpha</h3>
		<p>
			<i>Jun 30 2023, Introduction Prerelease</i>
		</p>
		<h4>New Features</h4>
		<ul>
			<li>Jarred's To-do list</li>
			<li>Sundown II development progress bar</li>
			<li>Sign in</li>
		</ul>
		<br> <br>

    <h1>Minor Versions</h1>
    <h3>1.1.2-prealpha (current)</h3>
    <p>
      <i>Aug 19 2024, Bug Fixes</i>
    </p>
    <h4>Improvements</h4>
    <ul>
      <li>Expired token sends user to separate sign in page to allow for graceful re-authentication</li>
    </ul>
    <br>
    <h3>1.1.1-prealpha</h3>
    <p>
      <i>Aug 15 2024, Bug Fixes</i>
    </p>
    <h4>Improvements</h4>
    <ul>
      <li>Clicking enter while in create and edit event forms no longer submits; Some input fields allow newline formatting.</li>
      <li>Updated Alpha screen for 1.0.0-alpha</li>
    </ul>
    <br> <br>
	</div>
</body>
</html>
