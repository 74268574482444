import { Component } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { Router } from '@angular/router';
import { SocialUser } from "@abacritt/angularx-social-login";
import { JediService } from '../shared/services/jedi.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { LoadService } from '../shared/services/load.service';

@Component({
	selector: 'app-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.css']
})
export class HomePageComponent {

	error: string = '';
	mobile: boolean = false;
	
	public loading(): boolean {
		return this.load.getLoading() > 0;
	}

	constructor(private socialAuthService: SocialAuthService,
		private router: Router, private service: JediService, public auth: AuthenticationService, private load: LoadService) {
	}

	ngOnInit() {
		this.auth.signIn().subscribe(() => {
		}, (err: any) => {
			this.error = 'Your token has expired, please reload the page and sign in again';
		});
		window.onresize = () => this.mobile = window.innerWidth <= 1100;
		this.mobile = window.innerWidth <= 1100;
	}

	async delay(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	refreshGoogleToken(): void {
		this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
	}

	navigatePrivacy() {
		this.router.navigate(['privacy']);
	}

	navigateCreateGroup() {
		this.router.navigate(['createGroup']);
	}

	navigateGroup(id: string) {
		this.router.navigate(['group', id]);
	}
}
