// src/app/create-event/create-event.component.ts
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JediService } from '../shared/services/jedi.service';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.css']
})
export class CreateEventComponent {
  error: string;
  id: number;
  name: string = '';
  desc: string = '';
  startDate: Date = new Date();
  endDate: Date = new Date();
  location: string = '';
  namePresent = true;
  descPresent = true;

  constructor(
    private service: JediService,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthenticationService
  ) {}

  ngOnInit() {
    this.error = '';
    this.auth.signIn().subscribe(() => {
      this.route.params.subscribe((params: any) => {
        this.id = params['id']
      }, (err: any) => {
        this.error = 'Your token has expired, please reload the page and sign in again';
        this.navigateHome();
      });
    });
  }

  navigateHome() {
    this.router.navigate(['home']);
  }

  navigateToGroup() {
    this.router.navigate(['group', this.id]);
  }

  private normalize() {
    this.desc = this.desc?.trim().replace(/(\r\n|\r|\n){2,}/g, '$1\n');
    this.location = this.location?.trim().replace(/(\r\n|\r|\n){2,}/g, '$1\n');
  }

  private validForm() {
    this.namePresent = this.name?.trim().length > 0 && this.name?.trim().length < 255;
    this.descPresent = !this.desc || this.desc?.trim().length < 100000;

    return this.namePresent && this.descPresent;
  }

  public valid() {
    return this.name?.trim().length > 0 && this.name?.trim().length < 255 && this.desc?.trim().length < 100000;
  }

  createEvent(): void {
    this.error = '';
    if (this.validForm()) {
      if (this.auth.getToken().length < 1 || this.auth.getUserId() === null) {
        this.auth.signIn().subscribe(() => {
        }, (err: any) => {
          this.error = 'Your token has expired, please reload the page and sign in again';
          this.navigateHome();
        });
      } else {
        this.normalize();
        console.log('Creating event', this.name, this.desc, this.startDate, this.endDate, this.location);
        this.service.createEvent(this.auth.getToken(), this.id, this.name.trim(), this.desc, this.startDate, this.endDate, this.location).subscribe((response: any) => {
          this.navigateToGroup();
        }, (err: any) => {
          this.error = err.message || err;
        });
      }
    }
  }
}
