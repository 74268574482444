<html>
<div class="errors" *ngIf="this.error?.length !== 0">
	<p class="errors">{{ this.error }}</p>
	<button class="noPad" (click)="this.error=''">X</button>
</div>
<body>
	<div class="loading" [hidden]="this.loading < 1">
		<img id='loadImg' src='../../assets/loading.png'>
	</div>
	<app-haus-header userName="{{ this.auth.getName() }}"
		photoUrl="{{ this.auth.getPhotoUrl() }}"
		userId="{{ this.auth.getUserId() }}"
		hideProfile="{{ this.profileOwner() }}"></app-haus-header>
	<div class="content">
		<table>
			<tr>
				<td><img id="userImg" src="{{ this.profile?.photoUrl }}"
					referrerpolicy="no-referrer"></td>
				<td><h1 id="userName">{{ this.profile?.name }}</h1></td>
				<td style="width: 15px"></td>
				<td><button *ngIf="this.profileOwner()"
						(click)="navigateEditUser(this.id)" class="actionButton">Edit
						Profile</button></td>
			</tr>
		</table>
		<br>
		<p id="bio" *ngIf="this.profile?.bio">{{ this.profile?.bio }}</p>
		<p class="break" id="userAge" *ngIf="this.profile.createdate">
			<i>Joined {{ this.getYear(this.profile?.createdate) }}</i>
		</p>
		<br>
		<table>
			<tr>
				<td><h1 class="dark">To Do List</h1></td>
				<td style="width: 15px"></td>
				<td *ngIf="this.profileOwner()"><button
						(click)="this.navigateTodos()" style="padding: 5px; float: right"
						class="actionButton">Manage List</button></td>
			</tr>
		</table>
		<div *ngFor="let todo of todos">
			<hr>
			<h3>{{ todo.name }}</h3>
			<h4 *ngIf="todo.deadlinename">{{ todo.deadlinename }}</h4>
			<div class="progress-container" *ngIf="todo.type===0">
				<progress id="progress" value="{{ todo.value }}" max="100"></progress>
				<label for="progress"><b>{{ todo.value + '%' }}</b></label>
			</div>
			<div class="progress-container" *ngIf="todo.type===1">
				<progress id="progress" value="{{ todo.value }}" max="{{ todo.max }}"></progress>
				<label for="progress"><b>{{ todo.value + '/' + todo.max }}</b></label>
			</div>
			<p *ngIf="todo.deadline && !todo.hastime">{{ this.daysFromNow(todo.deadline) }}</p>
				<p *ngIf="todo.deadline && todo.hastime">{{ this.timeUntilTarget(todo.deadline) }}</p>
				<p *ngIf="todo.createdate" style="font-size: 12px;">{{ 'Created ' + this.toDate(todo.createdate) }}</p>
		</div>
		<div *ngIf="todos?.length === 0">
			<h3 style="margin: 20px 0px -7px 20px">Nothing to do</h3>
			<p *ngIf="this.profileOwner()" style="margin-left: 20px">
				<i>Share your progress on your projects or goals</i>
			</p>
			<p *ngIf="!this.profileOwner()" style="margin-left: 20px">
				<i>They must have secret goals</i>
			</p>
		</div>
		<br>
	</div>
</body>
</html>
