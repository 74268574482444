import { Injectable } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { JediService } from '../services/jedi.service';
import { Observable, of } from 'rxjs';
import { SocialUser } from "@abacritt/angularx-social-login";
import { Router, ActivatedRoute } from '@angular/router';
import { Awss3Service } from './awss3.service';
import { LoadService } from './load.service';

type UserGroup = {
	id: string;
	name: string;
	desc: string;
	imageType: string;
	createdate: Date
};

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	constructor(private socialAuthService: SocialAuthService, private service: JediService, private router: Router, private awss3: Awss3Service, private route: ActivatedRoute, private load: LoadService) { }

	token: string;
	loggedIn: boolean;
	photoUrl: string;
	userName: string = 'Sign in  ';
	userId: string;
	user: SocialUser;
	groups: UserGroup[] = [];
	groupImages: Map<string, any> = new Map<string, any>();
	imageToShow: any;

	signIn(): Observable<any> {
		this.groups = [];
		this.groupImages = new Map<string, any>();
		let token = localStorage.getItem('idToken');
		if (token !== null) {
			this.token = token;
			this.load.incLoading();
			this.service.login(token).subscribe((response: any) => {
				this.loggedIn = true;
				this.photoUrl = response.photoUrl;
				this.userName = response.name;
				this.userId = response.id;
				this.groups = [];
				this.groupImages = new Map<string, any>();
				if (this.router.url === '/home') {
					this.loadGroups();
				}
			}, (err: any) => {
				this.localSignOut();
				throw Error('Sign-in error. Please Reload the Page. known Bug');
			}).add(() => {
				this.load.decLoading();
			});
		} else {
			this.socialAuthService.authState.subscribe((user: any) => {
				this.user = user;
				this.loggedIn = (user != null);

				if (this.loggedIn) {
					this.load.incLoading();
					this.service.login(user.idToken).subscribe((response: any) => {
						localStorage.setItem('idToken', user.idToken);
						this.photoUrl = response.photoUrl;
						this.userName = response.name;
						this.userId = this.user.id;
						this.token = user.idToken;
						this.groups = [];
						this.groupImages = new Map<string, any>();
						if (this.router.url === '/home') {
							this.loadGroups();
						}
					}).add(() => {
						this.load.decLoading();
						location.reload();
					});
				} else {
					this.groups = [];
					this.groupImages = new Map<string, any>();
					this.loggedIn = false;
					localStorage.removeItem('idToken');
					this.token = '';
					this.userId = '';
					this.photoUrl = '';
					this.userName = 'Sign in  ';
					this.groups = [];
					this.groupImages = new Map<string, any>();
				}
			});
		}
		return of('');
	}

	signOut(): void {
		this.socialAuthService.signOut();
		this.loggedIn = false;
		localStorage.removeItem('idToken');
		this.token = '';
		this.userId = '';
		this.photoUrl = '';
		this.userName = 'Sign in  ';
		this.groups = [];
		this.groupImages = new Map<string, any>();
    this.navigateSignIn();
	}

	localSignOut(): void {
		this.loggedIn = false;
		localStorage.removeItem('idToken');
		this.token = '';
		this.userId = '';
		this.photoUrl = '';
		this.userName = 'Sign in  ';
		this.groups = [];
		this.groupImages = new Map<string, any>();
		this.navigateSignIn();
	}

	private loadGroups() {
		this.load.incLoading();
		this.service.getUserGroups(this.token).subscribe((response: any) => {
			response.forEach((group: any) => {
				if (group.imageType == null) {
					this.load.incLoading();
					this.awss3.getGroupPhoto('0.png').subscribe((blob: any) => {
						this.createImageMapFromBlob(blob, group.id).then(() => {
							if (this.groups.filter(g => g.id === group.id).length === 0) {
								const indexToInsert = this.groups.findIndex(
									(obj) => new Date(group.createdate).getTime() < new Date(obj.createdate).getTime()
								);
								if (indexToInsert !== -1) {
									this.groups.splice(indexToInsert, 0, group);
								} else {
									// If the new object has the latest createdate, add it to the end
									this.groups.push(group);
								}
							}
						});
					}).add(() => {
						this.load.decLoading();
					});
				} else {
					let imageName = group.id + '.' + group.imageType;
					this.load.incLoading();
					this.awss3.getGroupPhoto(imageName).subscribe((blob: any) => {
						this.createImageMapFromBlob(blob, group.id).then(() => {
							if (this.groups.filter(g => g.id === group.id).length === 0) {
								const indexToInsert = this.groups.findIndex(
									(obj) => new Date(group.createdate).getTime() < new Date(obj.createdate).getTime()
								);
								if (indexToInsert !== -1) {
									this.groups.splice(indexToInsert, 0, group);
								} else {
									// If the new object has the latest createdate, add it to the end
									this.groups.push(group);
								}
							}
						});
					}).add(() => {
						this.load.decLoading();
					});
				}
			});
		}, (err: any) => {
			throw Error('Error loading your groups. Error Code: 1');
		}).add(() => {
			this.load.decLoading();
		});
	}

	private refreshGoogleToken() {
		return this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
	}

  public navigateSignIn() {
    this.router.navigate(['signIn']);
  }

	async createImageMapFromBlob(image: Blob, groupId: string) {
		let reader = new FileReader();
		reader.addEventListener("load", () => {
			this.imageToShow = reader.result;
			this.groupImages.set(groupId, reader.result);
		}, false);

		if (image) {
			reader.readAsDataURL(image);
		}
	}

	getUserId() {
		return this.userId;
	}

	getUser() {
		return this.user;
	}

	getName() {
		return this.userName;
	}

	getPhotoUrl() {
		return this.photoUrl;
	}

	getLoggedIn() {
		return this.loggedIn;
	}

	getToken() {
		return this.token;
	}

	getGroups() {
		return this.groups;
	}

	getGroupImages() {
		return this.groupImages;
	}
}
