import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as crypto from "crypto-js";
import { sha256 } from 'js-sha256';

@Injectable({
	providedIn: 'root'
})
export class Awss3Service {

	date: Date = new Date();
	
	baseUrl = 'https://s3.us-east-2.amazonaws.com/jedigroupavi/';

	getAuth(httpMethod: string, url: string, payload: any): string {
		const dateKey = crypto.HmacSHA256((this.date.toISOString().replace(/[^0-9A-Z]+/gi, "").substring(0, 8)), 'AWS4oP09uskb+3qWkqkCgjtZzROwbrGVXwtTGqEpPFNE');
		const dateRegionKey = crypto.HmacSHA256('us-east-2', dateKey);
		const dateRegionServiceKey = crypto.HmacSHA256('s3', dateRegionKey);
		const signingKey = crypto.HmacSHA256('aws4_request', dateRegionServiceKey);
		
		const hashedHexedPayload = sha256.hex(payload).toString();

		const canonicalRequest = httpMethod + '\n' + url + '\n\n' + 'host:s3.us-east-2.amazonaws.com\nx-amz-content-sha256:' + hashedHexedPayload + '\nx-amz-date:' + this.date.toISOString().replace(/[^0-9A-Z]+/gi, "").substring(0, 15) +
			'Z\n\nhost;x-amz-content-sha256;x-amz-date\n' + hashedHexedPayload;

		const stringToSign = 'AWS4-HMAC-SHA256' + '\n' + this.date.toISOString().replace(/[^0-9A-Z]+/gi, "").substring(0, 15) + 'Z' + '\n' +
			this.date.toISOString().replace(/[^0-9A-Z]+/gi, "").substring(0, 8) + '/us-east-2/s3/aws4_request' + '\n' + crypto.SHA256(canonicalRequest).toString();

		const signature = crypto.HmacSHA256(stringToSign, signingKey);

		return 'AWS4-HMAC-SHA256 Credential=AKIA56W5HYTBW34OL6ES/' + this.date.toISOString().replace(/[^0-9A-Z]+/gi, "").substring(0, 8) + '/us-east-2/s3/aws4_request, SignedHeaders=host;x-amz-content-sha256;x-amz-date, Signature=' + signature;
	}
	
	hexHashPayload(payload: any) {
		return crypto.SHA256(crypto.lib.WordArray.create(payload)).toString();
	}

	constructor(private http: HttpClient) { }

	public getGroupPhoto(filename: string): Observable<Blob> {
		const name = filename.split('.')[ 0 ];
		
		const url = this.baseUrl + 'group/' + name + '/' + filename;

		let headers = new HttpHeaders({
			'Authorization': this.getAuth('GET', '/jedigroupavi/group/' + name + '/' + filename, ''),
			'X-Amz-Content-Sha256': 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855',
			'X-Amz-Date': this.date.toISOString().replace(/[^0-9A-Z]+/gi, "").substring(0, 15) + 'Z'
		});

		return this.http.get(url, { headers: headers, responseType: 'blob' });
	}
	
	public setGroupPhoto(filename: string, file: Blob) {
		const name = filename.split('.')[ 0 ];
		
		const url = this.baseUrl + 'group/' + name + '/' + filename;

		let headers = new HttpHeaders({
			'Authorization': this.getAuth('PUT', '/jedigroupavi/group/' + name + '/' + filename, file),
			'X-Amz-Content-Sha256': this.hexHashPayload(file),
			'X-Amz-Date': this.date.toISOString().replace(/[^0-9A-Z]+/gi, "").substring(0, 15) + 'Z'
		});

		return this.http.put(url, file, { headers: headers, responseType: 'blob' });
	}
	
	public deleteGroupPhoto(filename: string): Observable<Blob> {
		const name = filename.split('.')[ 0 ];
		
		const url = this.baseUrl + 'group/' + name + '/' + filename;
		
		let headers = new HttpHeaders({
			'Authorization': this.getAuth('DELETE', '/jedigroupavi/group/' + name + '/' + filename, ''),
			'X-Amz-Content-Sha256': 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855',
			'X-Amz-Date': this.date.toISOString().replace(/[^0-9A-Z]+/gi, "").substring(0, 15) + 'Z'
		});

		return this.http.delete(url, { headers: headers, responseType: 'blob' });
	}
}
