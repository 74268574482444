import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JediService } from '../shared/services/jedi.service';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
  selector: 'app-edit-event',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.css']
})
export class EventEditComponent implements OnInit {
  event: any = {};
  eventId: number;
  id: number;
  namePresent = true;
  descPresent = true;

  error: string = '';

  constructor(
    private service: JediService,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthenticationService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      this.id = params['groupId'];
      this.loadEvent(this.eventId);
      this.adjustTextareaHeight();
    });
  }

  adjustTextareaHeight() {
    var textarea = document.getElementById('edesc') as HTMLTextAreaElement;
    var event = new Event('change'); // Create a new 'change' event
    textarea.dispatchEvent(event); // Dispatch the event

    var textarea = document.getElementById('location') as HTMLTextAreaElement;
    var event = new Event('change'); // Create a new 'change' event
    textarea.dispatchEvent(event); // Dispatch the event
  }

  ngAfterViewInit() {
    this.adjustTextareaHeight();
  }

  loadEvent(eventId: number) {
    let token = localStorage.getItem('idToken');
    if (token !== null) {
      this.service.getEvent(eventId, token).subscribe((event: any) => {
        this.event = event;
        this.adjustTextareaHeight();
      }, (err: any) => {
        console.error('Error loading event', err.message || err);
      });
    } else {
      console.error('Token expired');
    }
  }

  private validForm() {
    this.namePresent = this.event.name?.trim().length > 0 && this.event.name?.trim().length < 255;
    this.descPresent = !this.event.description || this.event.description?.trim().length < 100000;

    return this.namePresent && this.descPresent;
  }

  navigateToGroup() {
    this.router.navigate(['group', this.id]);
  }

  public valid() {
    return this.event.name?.trim().length > 0 && this.event.name?.trim().length < 255 && this.event.description?.trim().length < 100000;
  }

  onSubmit() {
    let token = localStorage.getItem('idToken');
    if (token !== null) {
      this.service.updateEvent(this.eventId, this.event.name, this.event.description, this.event.startTime, this.event.endTime, this.event.location, token).subscribe(() => {
        this.router.navigate(['group', this.id]);
      }, (err: any) => {
        console.error('Error updating event', err.message || err);
      });
    } else {
      console.error('Token expired');
    }
  }
}
