<html>
<div class="errors" *ngIf="this.error?.length !== 0">
  <p class="errors">{{ this.error }}</p>
  <button class="noPad" (click)="this.error=''">X</button>
</div>
<body>
<app-haus-header userName="{{ this.auth.getName() }}"
                 photoUrl="{{ this.auth.getPhotoUrl() }}" userId="{{ this.auth.getUserId() }}"></app-haus-header>
<div class="content">
  <div class="panel">
      <img class="headImg" id='groupImg'
           src='../../assets/editevent.png'> <br> <label
      class="light" for="ename">Event Name *</label><br> <input maxlength="255"
                                                                [(ngModel)]="event.name" type="text" id="ename" name="name">
      <p class="validation" *ngIf="!namePresent">Required Field</p>
      <br> <label class="light" for="edesc">Event
      Description</label><br>
      <textarea [(ngModel)]="event.description" type="text" maxlength="100000"
                oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                id="edesc" name="desc"></textarea>
      <br> <label class="light" for="startDate">New Start Date</label><br>
      <input [(ngModel)]="event.startTime" type="datetime-local" id="startDate" name="startDate">
      <br> <label class="light" for="endDate">New End Date</label><br>
      <input [(ngModel)]="event.endTime" type="datetime-local" id="endDate" name="endDate" [min]="event.startDate">
      <br> <label class="light" for="location">Location</label><br>
      <textarea [(ngModel)]="event.location" type="text" maxlength="255"
                oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                id="location" name="location"></textarea>
      <br><br>
      <button (click)="onSubmit()" [class.unclickable] = "!this.valid()">Edit Event</button>
      <button style="margin-left: 15px" (click)="navigateToGroup()">Cancel</button>
  </div>
</div>
</body>
</html>
