<html>
<div class="errors" *ngIf="this.error?.length !== 0">
	<p class="errors">{{ this.error }}</p>
	<button class="noPad" (click)="this.error=''">X</button>
</div>
<body>
	<div class="loading" [hidden]="!this.loading()">
		<img id='loadImg' src='../../assets/loading.png'>
	</div>
	<app-haus-header userName="{{ this.auth.getName() }}"
		photoUrl="{{ this.auth.getPhotoUrl() }}"
		userId="{{ this.auth.getUserId() }}"></app-haus-header>
	<div class="content">
		<div class="popup" *ngIf="this.confirmPopup">
			<h2>{{'Are you sure you want to leave ' + this.group?.name + '?'}}</h2>
			<h4>You will have to be invited back to the group to rejoin it if you leave.</h4>
			<br>
			<button class="cancel" (click)="cancel()">Cancel</button>
			<button (click)="confirmLeave()" style="margin-left: 10px;"
				class="confirm">Continue Leaving</button>
		</div>
		<table>
			<tr>
				<td><img id="groupImg" src="{{ this.groupImage }}"
					referrerpolicy="no-referrer"></td>
				<td><h1 id="groupName">{{ this.group?.name }}</h1>
					<p class="break" id="groupDesc">
						<i>{{ this.group?.desc }}</i>
					</p></td>
				<td style="width: 15px"></td>
				<td *ngIf="this.userRole === 'owner'"><button
						(click)="this.navigateManage()" class="actionButton">Manage
						Group</button></td>
				<td *ngIf="this.userRole">
					<button class="actionButton" (click)="this.navigateCreateInvite()">Invite
						Members</button>
				</td>
				<td *ngIf="this.userRole !== 'owner'">
					<button class="actionButton" (click)="this.popup()">Leave
						Group</button>
				</td>
			</tr>
			<tr>
				<td style="padding-left: 5px;"><p>{{ this.userCount +
						this.getMemberTranslation() }}</p></td>

			</tr>
		</table>

		<div class="groupPanel">
			<table>
				<tr>
					<td *ngIf="false">
						<button [class.active]="this.selectedIndex === 0"
							(click)="selected(0)">Announcements</button>
					</td>
					<td>
						<button [class.active]="this.selectedIndex === 1"
							(click)="selected(1)">Events</button>
					</td>
					<td>
						<button [class.active]="this.selectedIndex === 2"
							(click)="selected(2)">Members</button>
					</td>
					<td>
						<button [class.active]="this.selectedIndex === 3"
							(click)="selected(3)">Overview</button>
					</td>
					<td *ngIf="false">
						<button [class.active]="this.selectedIndex === 4"
							(click)="selected(4)">Others</button>
					</td>
				</tr>
			</table>
			<br>
			<div class="innerPanel" *ngIf="this.selectedIndex === 3">
				<div *ngIf="this.group?.motd">
					<h2>Message of the Day</h2>
					<p>{{ this.group?.motd }}</p>
					<br>
				</div>
				<div *ngIf="this.group?.createdate">
					<h4>Date of Creation</h4>
					<p>{{ this.group?.createdate }}</p>
				</div>
				<div *ngIf="false">
					<button class="darkHover">Badges</button>
				</div>
			</div>

			<div *ngIf="this.selectedIndex === 2">
				<div *ngIf="this.owner">
					<h2 class="light">Owner</h2>
					<div class="item" (click)="this.navigateUser(this.owner?.user?.id)">
						<table>
							<tr>
								<td><img id="avi" class="userAvi"
									src="{{ this.owner?.user?.photoUrl }}"></td>
								<td><h3>
										<b>{{ this.owner?.user?.name }}</b>
									</h3>
									<p id="joinDate">Joined {{ this.owner?.createdate }}</p></td>
							</tr>
						</table>
					</div>
				</div>
				<div *ngIf="this.adminList.length > 0">
					<br>
					<h4 class="light">Admins</h4>
					<div class="item" *ngFor="let admin of adminList"
						(click)="this.navigateUser(admin?.user?.id)">
						<table>
							<tr>
								<td><img id="avi" class="userAvi"
									src="{{ admin?.user?.photoUrl }}"></td>
								<td><h3>
										<b>{{ admin?.user?.name }}</b>
									</h3>
									<p id="joinDate">Joined {{ admin?.createdate }}</p></td>
							</tr>
						</table>
					</div>
				</div>
				<div *ngIf="this.modList.length > 0">
					<br>
					<h4 class="light">Moderators</h4>
					<div class="item" *ngFor="let mod of modList"
						(click)="this.navigateUser(mod?.user?.id)">
						<table>
							<tr>
								<td><img id="avi" class="userAvi"
									src="{{ mod?.user?.photoUrl }}"></td>
								<td><h3>
										<b>{{ mod?.user?.name }}</b>
									</h3>
									<p id="joinDate">Joined {{ mod?.createdate }}</p></td>
							</tr>
						</table>
					</div>
				</div>
				<div *ngIf="this.coreList.length > 0">
					<br>
					<h4 class="light">Members</h4>
					<div class="item" *ngFor="let core of coreList"
						(click)="this.navigateUser(core?.user?.id)">
						<table>
							<tr>
								<td><img id="avi" class="userAvi"
									src="{{ core?.user?.photoUrl }}"></td>
								<td><h3>
										<b>{{ core?.user?.name }}</b>
									</h3>
									<p id="joinDate">Joined {{ core?.createdate }}</p></td>
							</tr>
						</table>
					</div>
				</div>
				<div *ngIf="this.nonList.length > 0">
					<br>
					<h4 *ngIf="this.coreList.length === 0" class="light">Members</h4>
					<div class="item" *ngFor="let non of nonList"
						(click)="this.navigateUser(non?.user?.id)">
						<table>
							<tr>
								<td><img id="avi" class="userAvi"
									src="{{ non?.user?.photoUrl }}"></td>
								<td><h3>
										<b>{{ non?.user?.name }}</b>
									</h3>
									<p id="joinDate">Joined {{ non?.createdate }}</p></td>
							</tr>
						</table>
					</div>
				</div>
			</div>

			<div *ngIf="this.selectedIndex === 0">
				<div>
					<h2 class="light">Announcements</h2>
				</div>
			</div>

			<div *ngIf="this.selectedIndex === 1">
				<div>
					<h2 class="light">Events</h2>
          <div id="noEvents">
            <p class="light" *ngIf="this.events.length === 0">Nothing scheduled at the moment.</p>
          </div>
          <div *ngFor="let event of this.events; let i = index">
            <div class="innerPanel eventItem" (click)="this.navigateEvent(event.id)">
              <table>
                <tr>
                  <td>
                    <h1 id="eventName">
                      <b>{{ event.name }}</b>
                    </h1>
                    <br *ngIf="event?.description">
                    <p *ngIf="event?.description" class="dark" id="desc"><b>Description</b><br>{{ event.description }}</p>
                    <br *ngIf="event?.startTime || event?.endTime">
                    <p *ngIf="event?.startTime" class="dark" id="start"><b>Starts</b> {{ this.formatDateTime(event.startTime) }}</p>
                    <p *ngIf="event?.endTime" class="dark" id="end"><b>Ends</b> {{ this.formatDateTime(event.endTime) }}</p>
                    <br *ngIf="event?.location">
                    <p *ngIf="event?.location" class="dark" id="location"><b>Location</b><br>{{ event?.location }}</p>
                    <button *ngIf="event?.location" [id]="'copyButton' + i" type="button" (click)="copyToClipboard(event?.location, i)" class="actionButton">Copy Location</button>
                    <br><br>
                    <div style="display: flex; align-items: center;">
                      <img id="miniavi" src="{{ this.getUserPhotoFromID(event?.creator) }}">
                      <p id="creatorName"><b>{{ this.getUserFirstLastFromID(event?.creator) }}</b>&nbsp;&nbsp;&nbsp;<i>{{ this.formatDateTimeAbbr(event?.createdate) }}</i></p>
                    </div>
                    <br *ngIf="this.auth.getUserId() === event.creator">
                    <button *ngIf="this.auth.getUserId() === event.creator" (click)="confirmDelete(event.id)" class="actionButton">Delete Event</button>
                    <button *ngIf="this.auth.getUserId() === event.creator" (click)="navigateToEditEvent(event.id)" class="actionButton">Update Event</button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <br>
          <button (click)="navigateToCreateEvent()">+ Create Event</button>
          <br>
				</div>
			</div>

			<div class="innerPanel" *ngIf="this.selectedIndex === 4">
				<div>
					<h2>Trips</h2>
				</div>
			</div>
		</div><br>
	</div>

  <div class="popup" *ngIf="confirmDeletePopup">
    <h2>Are you sure you want to delete this event?</h2>
    <h4>This action cannot be undone.</h4>
    <br>
    <button class="cancel" (click)="cancelDelete()">Cancel</button>
    <button (click)="deleteEvent()" style="margin-left: 10px;" class="confirm">Delete</button>
  </div>
</body>
</html>
