<html>
<div class="errors" *ngIf="this.error?.length !== 0">
	<p class="errors">{{ this.error }}</p>
	<button class="noPad" (click)="this.error=''">X</button>
</div>
<body>
	<div class="loading" [hidden]="!this.loading()">
		<img id='loadImg' src='../../assets/loading.png'>
	</div>
	<app-haus-header userName="{{ this.auth.getName() }}"
		photoUrl="{{ this.auth.getPhotoUrl() }}"
		userId="{{ this.auth.getUserId() }}"></app-haus-header>
	<div class="popup" *ngIf="this.deletePopup">
		<h2>Delete this Group?</h2>
		<h4>Are you sure you want to delete this group?</h4>
		<br>
		<button class="cancel" (click)="quitPopup()">Cancel</button>
		<button (click)="confirmDelete()" style="margin-left: 10px;"
			class="confirm">Delete</button>
	</div>
	<div class="content">
		<div *ngIf="!this.authorized">
			<h1>You are not authorized to edit</h1>
			<h4>Sorry.</h4>
		</div>
		<div *ngIf="this.authorized">
			<div class="panel">
				<button (click)="showPopup()">Delete Group</button>
			</div><br>
			<div class="panel">
				<form (submit)="editGroup()">
					<img class="headImg" id='groupImg' src='../../assets/editGroup.png'>
					<br> <label class="light" for="image">Group Icon</label> <br>
					<img *ngIf="namePresent" id="image" name="image" [src]="this.url"
						style="object-fit: cover" height="200" width="200"> <br />
					<input class="custom-file-input" type='file' id="fileSelection"
						(change)="onSelectFile($event)"
						accept="image/png, image/jpeg, image/tiff, image/jpg, image/gif">
					<p class="validation" *ngIf="!namePresent">Must be an image
						with file type of png, jpeg, jpg, gif, or tiff</p>
					<br> <br> <br> <label class="light" for="name">Name</label><br>
					<input maxlength="24" [(ngModel)]="name" type="text" id="name"
						name="name"> <br> <label class="light" for="desc">Description</label><br>
					<textarea [(ngModel)]="desc" type="text" maxlength="100"
						oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
						id="desc" name="desc"></textarea>
					<br> <label class="light" for="motd">Message of the
						Day</label><br>
					<textarea [(ngModel)]="motd" type="text" maxlength="140"
						oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
						id="motd" name="motd"></textarea>
					<br> <br>
					<button type="submit" [class.unclickable]="!this.changed()">Submit
						Changes</button>
					<button style="margin-left: 15px" (click)="cancel()">Cancel</button>
				</form>
			</div>
			<br>
		</div>
	</div>
</body>
</html>

