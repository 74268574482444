import { Component } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { Router, ActivatedRoute } from '@angular/router';
import { SocialUser } from "@abacritt/angularx-social-login";
import { AuthenticationService } from '../shared/services/authentication.service';
import { JediService } from '../shared/services/jedi.service';

@Component({
	selector: 'app-todo-manage',
	templateUrl: './todo-manage.component.html',
	styleUrls: ['./todo-manage.component.css']
})
export class TodoManageComponent {

	constructor(private socialAuthService: SocialAuthService,
		private router: Router, public auth: AuthenticationService, private route: ActivatedRoute, private service: JediService) {
	}

	error: string = '';
	id: string = '';
	loading: number = 0;
	todos: any[];

	idToDelete: number = 0;

	editingItem: boolean = false;
	editMade: boolean = false;
	confirmPopup: boolean = false;
	deletePopup: boolean = false;

	addName: string = '';
	addDeadlinename: string = '';
	addType: string = '0';
	addValue: number = 0;
	addMax: number = 0;
	addHastime: boolean = false;
	addDeadline: Date;
	addTime: Date;
	saved: boolean = false;

	ngOnInit() {
		this.route.params.subscribe((params: any) => {
			this.id = params['id']
			this.auth.signIn().subscribe(() => {
				this.service.getTodos(this.auth.getToken(), this.id).subscribe((response: any) => {
					this.todos = response;
				}, (err: any) => {
					this.error = 'Error loading to do list';
				});
			}, (err: any) => {
				this.error = 'Your token has expired, please reload the page and sign in again';
				this.navigateHome();
			});
		});
	}

	navigateHome() {
		this.router.navigate(['home']);
	}

	popup() {
		this.confirmPopup = true;
	}

	popupDelete() {
		this.deletePopup = true;
	}

	cancel() {
		this.confirmPopup = false;
		this.deletePopup = false;
	}

	deleteItem(todoID: number) {
		this.deletePopup = true;
		this.idToDelete = todoID;
	}

	confirmDelete() {
		this.todos = this.todos.filter((todo) => {
			return todo.id !== this.idToDelete;
		});
		this.service.deleteTodo(this.auth.getToken(), this.id, this.idToDelete).subscribe(() => {
		}, (err: any) => {
			this.error = 'Error saving todos';
		});
		this.deletePopup = false;
	}

	save() {
		this.loading++;
		this.service.processTodos(this.auth.getToken(), this.todos).subscribe(() => {
			this.editMade = false;
			this.saved = true;
		}, (err: any) => {
			this.error = 'Error saving todos';
		}).add(() => {
			this.loading--;
		});
	}

	addItem() {
		this.todos.push(
			{
				name: this.addName,
				value: this.addValue,
				max: this.addMax,
				deadline: this.addHastime ? new Date(this.addDeadline.toString() + 'T' + this.addTime.toString()) : this.addDeadline,
				type: +this.addType,
				userid: this.id,
				hastime: this.addHastime,
				deadlinename: this.addDeadlinename,
				id: -1
			}
		);

		this.addName = '';
		this.addDeadlinename = '';
		this.editMade = true;
		this.saved = false;
		this.addValue = 0;
		this.addMax = 0;
		this.addDeadline = new Date();
	}

	typeChange() {
		this.addValue = 0;
		this.addMax = 0;
	}

	onChange() {
		this.editMade = true;
		this.saved = false;
	}

	navigateBack() {
		if (this.editMade) {
			this.popup();
		} else {
			this.router.navigate(['profile', this.id]);
		}
	}

	confirmNavigateBack() {
		this.router.navigate(['profile', this.id]);
	}

	public profileOwner(): boolean {
		return this.auth.getUserId() === this.id;
	}

	public parseDate(date: Date): string {
		const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', year: 'numeric' };

		return date.toLocaleDateString("en-US", options);
	}
}
