import { Component } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { Router, ActivatedRoute } from '@angular/router';
import { SocialUser } from "@abacritt/angularx-social-login";
import { JediService } from '../shared/services/jedi.service';
import { Awss3Service } from '../shared/services/awss3.service';
import { LoadService } from '../shared/services/load.service';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
	selector: 'app-profile-edit',
	templateUrl: './profile-edit.component.html',
	styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent {

	constructor(private socialAuthService: SocialAuthService,
		private router: Router, private service: JediService, private awss3: Awss3Service, private route: ActivatedRoute, public auth: AuthenticationService, private load: LoadService) {
	}

	public loading(): boolean {
		return this.load.getLoading() > 0;
	}

	loggedIn: boolean;
	namePresent: boolean = true;
	name: string = '';
	id: string = '';
	bio: string = '';
	profile: any = { photoUrl: '../../assets/defaultGroup.png' };
	authorized: boolean = true;
	ogName = '';
	ogBio = '';

	error: string = '';

	ngOnInit() {
		this.auth.signIn();
		this.route.params.subscribe((params: any) => {
			this.id = params['id'];
			if (this.auth.getToken() !== null) {
				this.service.getProfile(this.auth.getToken(), this.id).subscribe((response: any) => {
					this.profile = response;
					this.name = response.name
					this.ogName = response.name;
					this.bio = response.bio === null ? '' : response.bio;
					this.ogBio = response.bio === null ? '' : response.bio;
				}, (err: any) => {
					this.error = 'Error loading profile';
				});
			}
		});
	}

	validateForm() {
		if (this.name.trim().length === 0) {
			this.namePresent = false;
		} else {
			this.namePresent = true;
		}
	}

	public changed(): boolean {
		return this.name !== this.ogName || this.bio !== this.ogBio;
	}

	public editProfile() {
		this.validateForm();
		if (this.namePresent) {
			let token = localStorage.getItem('idToken');
			if (token !== null) {
				this.service.editProfile(token, this.auth.getUserId(), this.name.trim(), this.bio.trim()).subscribe((response: any) => {
					this.router.navigate(['profile', this.id]);
				});
			}
		}
	}

	cancel() {
		this.router.navigate(['profile', this.id]);
	}
	
	navigateHome() {
		this.router.navigate(['home']);
	}


}
