<html>
<div class="errors" *ngIf="this.error?.length !== 0">
	<p class="errors">{{ this.error }}</p>
	<button class="noPad" (click)="this.error=''">X</button>
</div>
<body>
	<app-haus-header userName="{{ this.auth.getName() }}"
		photoUrl="{{ this.auth.getPhotoUrl() }}"
		userId="{{ this.auth.getUserId() }}"></app-haus-header>
	<div class="content">
		<h1 id="manageTodo">JediHaus Group Invite</h1>
		<h2 id="manageTodo" *ngIf="!this.auth.getLoggedIn()">In order to use this invite, you must be logged in.</h2>
		<h2 id="manageTodo" *ngIf="this.auth.getLoggedIn() && !this.group">Please refresh the page</h2>
		<div id="homeGroups" class="panel" *ngIf="this.group">
			<table>
				<tr>
					<td><img id="knownGroups" class="groupAvi" src="{{ this.groupImage }}"
						referrerpolicy="no-referrer"></td>
					<td>
						<h2 class="light">{{ this.group?.name }}</h2>
						<h4 class="light" style="margin: 5px 0 5px 0">{{
							this.group?.desc }}</h4>
					</td>
				</tr>
			</table>
		</div>
		<button style="margin-top: 15px" (click)="joinGroup()"
			*ngIf="this.auth.getLoggedIn() && this.group">Join Group</button>
	</div>
</body>
</html>
