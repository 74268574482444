import { Component } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { Router, ActivatedRoute } from '@angular/router';
import { JediService } from '../shared/services/jedi.service';
import { LoadService } from '../shared/services/load.service';
import { Awss3Service } from '../shared/services/awss3.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { BrowserModule, Title } from '@angular/platform-browser';


@Component({
	selector: 'app-group-view',
	templateUrl: './group-view.component.html',
	styleUrls: ['./group-view.component.css']
})
export class GroupViewComponent {
	constructor(private socialAuthService: SocialAuthService,
		private router: Router, private service: JediService, private awss3: Awss3Service, private route: ActivatedRoute, private title: Title, public auth: AuthenticationService,
		private load: LoadService) {
	}

	public loading(): boolean {
		return this.load.getLoading() > 0;
	}

	GoogleLoginProvider = GoogleLoginProvider;

	userRole: string = 'non';
	name: string = '';
	desc: string = '';
	motd: string = '';
	id: number;
	userCount = 0;
	group: any;
	groupImage: any;
	selectedIndex = 1;
	owner: any;
  userList: any[] = [];
	adminList: any[] = [];
	modList: any[] = [];
	coreList: any[] = [];
	nonList: any[] = [];
	confirmPopup: boolean = false;
  confirmDeletePopup: boolean = false;
  eventIdToDelete: number | null = null;
  events: any[] = [];

	error: string = '';

	ngOnInit() {
		this.load.incLoading();
		this.auth.signIn().subscribe(() => {
			this.route.params.subscribe((params: any) => {
				this.id = params['id']

				let token = localStorage.getItem('idToken');
				if (token !== null) {
					this.load.incLoading();
					this.service.getGroup(token, this.id).subscribe((response: any) => {
						this.group = response;
						this.title.setTitle(response.name + ' | JediHaus Group');
						this.loadImage();
						this.populateContent();
					}, (err: any) => {
						this.error = 'Error loading group';
						this.navigateHome();
					}).add(() => {
						this.load.decLoading();
					});
				} else {
					this.error = 'Your token has expired, please reload the page and sign in again';
				}

			}, (err: any) => {
				this.error = 'Error loading group';
				this.navigateHome();
			});
		}, (err: any) => {
			this.error = 'Your token has expired, please reload the page and sign in again';
			this.navigateHome();
		}).add(() => {
			this.load.decLoading();
		});
	}

	async populateContent(): Promise<void> {
		let token = localStorage.getItem('idToken');
		if (token !== null) {
			this.load.incLoading();
			this.service.getGroupUsers(token, this.id).subscribe((response: any) => {
				this.userCount = response?.length;
        this.userList = response;
				response.forEach((usergroup: any) => {
					if (usergroup.user.id === this.auth.getUserId()) {
						this.userRole = usergroup.role;
					}
					usergroup.createdate = this.parseDate(usergroup.createdate);
					if (usergroup.user.id === this.group.owner && usergroup.role === 'owner') {
						this.owner = usergroup;
					} else if (usergroup.role === 'admin') {
						this.adminList.push(usergroup);
					} else if (usergroup.role === 'moderator') {
						this.modList.push(usergroup);
					} else if (usergroup.role === 'core') {
						this.coreList.push(usergroup);
					} else {
						this.nonList.push(usergroup);
					}
				});
			}, (err: any) => {
				this.error = 'Error loading group members. Error Code: 2';
			}).add(() => {
				this.load.decLoading();
			});
      this.service.getGroupEvents(token, this.id).subscribe((response: any) => {
        this.events = response;
      }, (err: any) => {
        this.error = 'Error loading group events. Error Code: 3';
      }).add(() => {
        this.load.decLoading();
      });
		}
	}

  copyToClipboard(text: string, index: number) {
    navigator.clipboard.writeText(text);

    const button = document.getElementById("copyButton" + index);

    button?.classList.add('greenButton');
    setTimeout(() => {
      button?.classList.remove('greenButton');
    }, 1000);
  }

	navigateHome() {
		this.router.navigate(['home']);
	}

  navigateEvent(id: number) {
    //this.router.navigate(['event', id]);
  }

	popup() {
		this.confirmPopup = true;
	}

  formatDateTime(dateS: string): string {

    let date = new Date(dateS);
    const localDate = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long', // e.g., 'Monday'
      month: 'long', // e.g., 'August'
      day: 'numeric', // e.g., '13'
      year: 'numeric', // e.g., '2024'
      hour: 'numeric', // e.g., '10'
      minute: '2-digit', // e.g., '07'
      hour12: true, // Use 12-hour format with AM/PM
      timeZoneName: 'short' // e.g., 'EDT'
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);

    // Format date parts
    const parts = formatter.formatToParts(localDate);
    const weekday = parts.find((part: { type: string; }) => part.type === 'weekday')?.value || '';
    const month = parts.find((part: { type: string; }) => part.type === 'month')?.value || '';
    const day = parts.find((part: { type: string; }) => part.type === 'day')?.value || '';
    const year = parts.find((part: { type: string; }) => part.type === 'year')?.value || '';
    const hour = parts.find((part: { type: string; }) => part.type === 'hour')?.value || '';
    const minute = parts.find((part: { type: string; }) => part.type === 'minute')?.value || '';
    const dayPeriod = parts.find((part: { type: string; }) => part.type === 'dayPeriod')?.value || '';
    const timeZone = parts.find((part: { type: string; }) => part.type === 'timeZoneName')?.value || '';

// Combine into the final string
    const formattedDate = `${weekday}, ${month} ${day}, ${year} at ${hour}:${minute} ${dayPeriod} ${timeZone}`;

    return formattedDate;
  }

  formatDateTimeAbbr(dateS: string): string {

    let date = new Date(dateS);
    const localDate = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));

    const options: Intl.DateTimeFormatOptions = {
      month: 'long', // e.g., 'August'
      day: 'numeric', // e.g., '13'
      year: 'numeric', // e.g., '2024'
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);

    // Format date parts
    const parts = formatter.formatToParts(localDate);
    const month = parts.find((part: { type: string; }) => part.type === 'month')?.value || '';
    const day = parts.find((part: { type: string; }) => part.type === 'day')?.value || '';
    const year = parts.find((part: { type: string; }) => part.type === 'year')?.value || '';

// Combine into the final string
    const formattedDate = `${month} ${day}, ${year}`;

    return formattedDate;
  }

	confirmLeave() {
		this.service.leaveGroup(this.auth.getToken(), this.id).subscribe(() => {
		}, (err: any) => {
			this.error = 'Error leaving group. If you are the owner, you currently can\'t leave.';
		});
		this.confirmPopup = false;
		this.navigateHome();
	}

  getUserFirstLastFromID(id: string): string {
    let user = this.userList.find((user: any) => user.user.id === id);
    if (user) {
      return user.user.name;
    }
    return '';
  }

  getUserPhotoFromID(id: string): string {
    let user = this.userList.find((user: any) => user.user.id === id);
    if (user) {
      return user.user.photoUrl;
    }
    return '';
  }

	cancel() {
		this.confirmPopup = false;
	}

	private loadImage() {
		if (this.group.imagetype == null) {
			this.groupImage = '../../assets/defaultGroup.png';
		} else {
			let imageName = this.group.id + '.' + this.group.imagetype;
			this.load.incLoading();
			this.awss3.getGroupPhoto(imageName).subscribe((blob: any) => {
				this.createImageMapFromBlob(blob, this.group.id).then(() => {
					this.load.decLoading();
				});
			});
		}
	}

	async createImageMapFromBlob(image: Blob, groupId: string) {
		let reader = new FileReader();
		reader.addEventListener("load", () => {
			this.groupImage = reader.result;
		}, false);

		if (image) {
			reader.readAsDataURL(image);
		}
	}

	getMemberTranslation(): string {
		if (this.userCount === 1) {
			return ' member';
		} else {
			return ' members';
		}
	}

	navigateManage() {
		this.router.navigate(['group', 'edit', this.id]);
	}

	selected(index: number) {
		this.selectedIndex = index;
	}

	navigateUser(id: string) {
		this.router.navigate(['profile', id]);
	}

	navigateCreateInvite() {
		this.router.navigate(['createInvite', this.id]);
	}

  navigateToCreateEvent(): void {
    this.router.navigate(['createEvent', this.id]);
  }

	parseDate(dateString: string): string {
		const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', year: 'numeric' };

		return new Date(dateString).toLocaleDateString("en-US", options);
	}

  confirmDelete(eventId: number) {
    this.eventIdToDelete = eventId;
    this.confirmDeletePopup = true;
  }

  cancelDelete() {
    this.confirmDeletePopup = false;
    this.eventIdToDelete = null;
  }

  deleteEvent() {
    if (this.eventIdToDelete !== null) {
      let token = localStorage.getItem('idToken');
      if (token !== null) {
        this.service.deleteEvent(this.eventIdToDelete, token).subscribe(() => {
          this.events = this.events.filter(event => event.id !== this.eventIdToDelete);
          this.confirmDeletePopup = false;
          this.eventIdToDelete = null;
        }, (err: any) => {
          this.error = err.message || err;
          this.confirmDeletePopup = false;
          this.eventIdToDelete = null;
        });
      } else {
        this.error = 'Your token has expired, please reload the page and sign in again';
        this.navigateHome();
      }
    }
  }

  navigateToEditEvent(eventId: number) {
    this.router.navigate(['editEvent', this.id, eventId]);
  }

}
