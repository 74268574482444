<html>
<div class="errors" *ngIf="this.error?.length !== 0">
	<p class="errors">{{ this.error }}</p>
	<button class="noPad" (click)="this.error=''">X</button>
</div>
<body>
	<app-haus-header userName="{{ this.auth.getName() }}"
		photoUrl="{{ this.auth.getPhotoUrl() }}" userId="{{ this.auth.getUserId()}}"></app-haus-header>
	<div class="content">
		<div class="panel">
			<form (submit)="createGroup()">
				<img class="headImg" id='groupImg'
					src='../../assets/creategroup.png'> <br> <label
					class="light" for="gname">Group Name *</label><br> <input maxlength="24"
					[(ngModel)]="name" type="text" id="gname" name="name">
				<p class="validation" *ngIf="!namePresent">Required Field</p>
				<br *ngIf="namePresent"> <label class="light" for="gdesc">Group
					Description *</label><br>
				<textarea [(ngModel)]="desc" type="text" maxlength="100"
					oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
					id="gdesc" name="desc"></textarea>
				<p class="validation" *ngIf="!descPresent">Required Field</p>
				<br *ngIf="descPresent"> <label class="light" for="gmotd">Group
					MOTD</label><br> <textarea [(ngModel)]="motd" type="text" id="gmotd" maxlength="140"
					oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
					name="motd"></textarea><br>
				<p class="light note">You will be able to update the group
					image, invite members, and transfer ownership once the group is
					created.</p>
				<br>
				<button type="submit" [class.unclickable] = "!this.valid()">Create Group</button>
				<button style="margin-left: 15px" (click)="cancel()">Cancel</button>
			</form>
		</div>
	</div>
</body>
</html>
