<html>
<head>
<link rel="stylesheet"
	href="https://fonts.googleapis.com/css?family=Roboto">
</head>
<body>
	<div id="menuEscape" *ngIf="this.showUserMenu"
		(click)="this.toggleUserMenu()"></div>
	<div id='bg'>
		<button id="alpha" (click)="this.navigateAlpha()">Alpha Info</button>
		<table id="headTable">
			<tr>
				<td><img id='headImg' (click)="this.navigateHome()" src='../../../../assets/JHLightA.png'></td>
				<td id="loggedInTD" class="profile"
					*ngIf="this.loggedIn() && this.photoUrl?.length !== 0"
					(click)="this.toggleUserMenu()"><img id="proPhoto"
					src="{{ this.photoUrl }}" referrerpolicy="no-referrer">
					<p id="name" class="light">{{ this.userName }}</p></td>
				<td id="loggedOutTD" class="profile"
					*ngIf="!this.loggedIn() || this.photoUrl?.length === 0"
					(click)="this.toggleUserMenu()">
					<p id="name" class="light">{{ this.userName }}</p>
				</td>
			</tr>
		</table>
	</div>
	<div id="fill"></div>
	<div id="userMenu" *ngIf="this.showUserMenu">
		<table class="menuTable"
			*ngIf="this.loggedIn() && this.photoUrl?.length !== 0">
			<tr>
				<td><button style="margin: 2px" class="menuContent"
						(click)="this.navigateProfile()">My Profile</button></td>
			</tr>
			<tr>
				<td><button style="margin: 2px" class="menuContent"
						(click)="this.signOut()">Sign out</button></td>
			</tr>
		</table>
		<table class="menuTable"
			*ngIf="(!this.loggedIn() || this.photoUrl?.length === 0)">
			<tr>
				<td><asl-google-signin-button id="gsi" type='standard'
						size='medium'></asl-google-signin-button></td>
			</tr>
			<tr>
				<td><button style="margin: 2px; font-size: 12px"
						class="menuContent" (click)="this.navigatePrivacy()">Privacy
						Policy</button></td>
			</tr>
		</table>
	</div>
</body>
</html>