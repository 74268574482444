<html>
<div class="errors" *ngIf="this.error?.length !== 0">
	<p class="errors">{{ this.error }}</p>
	<button class="noPad" (click)="this.error=''">X</button>
</div>
<body>
	<div class="loading" [hidden]="!this.loading()">
		<img id='loadImg' src='../../assets/loading.png'>
	</div>
	<app-haus-header userName="{{ this.auth.getName() }}"
		photoUrl="{{ this.auth.getPhotoUrl() }}" userId="{{ this.auth.getUserId() }}"></app-haus-header>
	<div class="content">
		<table id="contentTable">
			<tr>
				<td style="width: 50%"><div class="panel" id="groupsPanel">
						<img class="headImg" id='groupImg' src='../../assets/groups.png'>
						<button style="float: right;" (click)="navigateCreateGroup()"
							*ngIf="this.auth.getLoggedIn() && this.auth.getGroups().length > 0">+ Create
							Group</button>
						<div id="homeGroups" class="item" *ngFor="let group of this.auth.getGroups()"
							(click)="this.navigateGroup(group.id)">
							<table>
								<tr>
									<td><img id="knownGroups" class="groupAvi"
										src="{{ this.auth.getGroupImages().get(group.id) }}"
										referrerpolicy="no-referrer"></td>
									<td>
										<h2>{{ group?.name }}</h2>
										<p style="margin: 5px 0 5px 0" class="break">{{
											group?.desc }}</p>
									</td>
								</tr>
							</table>
						</div>
						<div class="item"
							*ngIf="this.auth.getGroups().length === 0 && this.auth.getLoggedIn()"
							(click)="navigateCreateGroup()">
							<table>
								<tr>
									<td><img id="noGroups" class="groupAvi"
										src="../../assets/plus.png" referrerpolicy="no-referrer"></td>
									<td>
										<h2>No Groups</h2>
										<p style="margin: 5px 0 5px 0">Create a group and invite
											your friends!</p>
									</td>
								</tr>
							</table>
						</div>
						<div *ngIf="!this.auth.getLoggedIn()" style="padding: 15px">
							<table>
								<tr>
									<td></td>
									<td>
										<h2 class="light">No Groups</h2>
										<p class="light" style="margin: 5px 0 5px 0">Sign in to
											see your groups!</p>
									</td>
								</tr>
							</table>
						</div>
					</div></td><br>
				<td *ngIf="!this.mobile" style="vertical-align: top;">
					<div class="panel" id="sidebar">
						<h1 class="light">Welcome to JediHaus!</h1>
						<p class="light">At the moment, all content is found in Groups and user profiles.</p>
						<p class="light">Join or create a Group to begin!</p>
						<br>
						<p class="light" style="font-size:14px"><i>The app is currently in its alpha development stage. For more information, or to report any bugs, navigate to the Alpha Info page by clicking the button in the bottom right corner of any page.</i></p>
            <br>
            <p class="light">"Always remember, your focus determines your reality."</p>
            <p class="light">— Qui-Gon Jinn</p>
					</div>
				</td>
			</tr>
		</table>
	</div>
</body>
</html>
