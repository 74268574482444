import { Component } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { Router, ActivatedRoute } from '@angular/router';
import { SocialUser } from "@abacritt/angularx-social-login";
import { JediService } from '../shared/services/jedi.service';
import { Awss3Service } from '../shared/services/awss3.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { BrowserModule, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-profile-view',
	templateUrl: './profile-view.component.html',
	styleUrls: ['./profile-view.component.css']
})
export class ProfileViewComponent {

	constructor(private socialAuthService: SocialAuthService,
		private router: Router, private service: JediService, private awss3: Awss3Service, public auth: AuthenticationService, private route: ActivatedRoute, private title: Title) {
	}

	GoogleLoginProvider = GoogleLoginProvider;

	id: string = '';
	loading: number = 0;
	todos: any[];
	profile: any = { photoUrl: '../../assets/defaultGroup.png' };

	monthNames = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];

	error: string = '';

	ngOnInit() {
		this.route.params.subscribe((params: any) => {
			this.id = params['id']
			this.loading++;
			this.auth.signIn().subscribe(() => {
				this.loading++;
				this.service.getProfile(this.auth.getToken(), this.id).subscribe((response: any) => {
					this.title.setTitle(response.name + ' | JediHaus Profile');
					this.profile = response;
					this.loading++;
					this.service.getTodos(this.auth.getToken(), this.id).subscribe((response: any) => {
						this.todos = response;
					}, (err: any) => {
						this.error = 'Error loading to';
					}).add(() => {
						this.loading--;
					});
				}, (err: any) => {
					this.error = 'Error loading profile';
				}).add(() => {
					this.loading--;
				});
			}, (err: any) => {
				this.error = 'Your token has expired, please reload the page and sign in again';
				this.navigateHome();
			}).add(() => {
				this.loading--;
			});
		}).add(() => {
			this.loading--;
		});
	}

	public getYear(dateString: string) {
		let d = new Date(Date.parse(dateString));
		return this.monthNames[d.getMonth()] + ' ' + d.getFullYear();
	}

	public profileOwner(): boolean {
		return this.auth.getUserId() === this.id;
	}

	navigateEditUser(id: string) {
		if (this.profileOwner()) {
			this.router.navigate(['profile', 'edit', id]);
		} else {
			this.error = 'Either you\'ve hacked the website, or the website is a hack. Fatal Error, please report code 0';
		}
	}

	navigateTodos() {
		if (this.profileOwner()) {
			this.router.navigate(['todos', 'edit', this.id]);
		} else {
			this.error = 'Either you\'ve hacked the website, or the website is a hack. Fatal Error, please report code 0';
		}
	}

	navigateHome() {
		this.router.navigate(['home']);
	}

	daysFromNow(targetDate: string): string {
		const currentDate = new Date();
		const targetDateObj = new Date(targetDate);

		const timeDiff = targetDateObj.getTime() - currentDate.getTime();

		// Calculate the difference in days
		const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

		if (daysDiff > 0) {
			return daysDiff + ' days until deadline';
		} else if (daysDiff < 0 && daysDiff > -1) {
			return 'Just past deadline';
		} else if (daysDiff < 0) {
			return (-1 * daysDiff) + ' days past deadline';
		} else {
			return 'Deadline imminent';
		}

	}

	timeUntilTarget(targetDate: string): string {
		const currentDate = new Date();
		const targetDateObj = new Date(targetDate);

		// Calculate the difference in milliseconds
		const timeDiff = targetDateObj.getTime() - currentDate.getTime();

		// Calculate days, hours, and minutes
		const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
		const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

		let response = '';

		if (days === 0 && hours === 0 && minutes === 0) {
			response = 'Deadline imminent'
		} else {
			if (days < 0 || hours < 0 || minutes < 0) {
				return this.daysFromNow(targetDate);
			} else if (days === 1) {
				response += (days + ' day, ');
			} else {
				response += (days + ' days, ');
			}

			if (hours === 1) {
				response += (hours + ' hour, and ');
			} else {
				response += (hours + ' hours, and ');
			}

			if (minutes === 1) {
				response += (minutes + ' minute until the deadline');
			} else {
				response += (minutes + ' minutes until the deadline');
			}
		}

		return response;
	}

	toDate(date: string): string {
		const targetDateObj = new Date(date);
		return targetDateObj.toLocaleDateString('en-US');
	}

}
