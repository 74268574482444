import { Component } from '@angular/core';
import { JediService } from '../shared/services/jedi.service';
import { GoogleLoginProvider, SocialAuthService, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { SocialUser } from "@abacritt/angularx-social-login";
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
	selector: 'app-privacy-notice',
	templateUrl: './privacy-notice.component.html',
	styleUrls: ['./privacy-notice.component.css']
})
export class PrivacyNoticeComponent {

	user: SocialUser;
	userName: string;
	photoUrl: string;
	loggedIn: boolean;

	constructor(private service: JediService, private socialAuthService: SocialAuthService, public auth: AuthenticationService) {
	}

	ngOnInit() {
		this.auth.signIn();
	}
}
