<div class="content">
  <div class="panel">
    <img src="../../../../assets/JHLightA.png" style="height: 50px">
    <div class="content" *ngIf="this.auth.loggedIn">
      <h1 class="light" id="name">Welcome, {{ this.auth.userName }}</h1>

      <img id="proPhoto" class="groupAvi" *ngIf="this.auth.photoUrl?.length !== 0" src="{{ this.auth.photoUrl }}" referrerpolicy="no-referrer">
      <br><br><button (click)="this.navigateHome()">Continue to JediHaus</button>
    </div>
    <div class="content" *ngIf="!this.auth.loggedIn">
      <h1 class="light">Please sign in</h1><br>
      <asl-google-signin-button id="gsi" type='standard'
                                size='medium'></asl-google-signin-button>
    </div>
  </div>
</div>


