<html>
<div class="errors" *ngIf="this.error?.length !== 0">
	<p class="errors">{{ this.error }}</p>
	<button class="noPad" (click)="this.error=''">X</button>
</div>
<body>
	<div class="loading" [hidden]="this.loading < 1">
		<img id='loadImg' src='../../assets/loading.png'>
	</div>
	<app-haus-header userName="{{ this.auth.getName() }}"
		photoUrl="{{ this.auth.getPhotoUrl() }}"
		userId="{{ this.auth.getUserId() }}"></app-haus-header>
	<div class="popup" *ngIf="this.confirmPopup">
		<h2>Leaving without saving...</h2>
		<h4>This will result in the loss of your current changes</h4>
		<br>
		<button class="cancel" (click)="cancel()">Cancel</button>
		<button (click)="confirmNavigateBack()" style="margin-left: 10px;"
			class="confirm">Continue Leaving</button>
	</div>
	<div class="popup" *ngIf="this.deletePopup">
		<h2>Delete this Item?</h2>
		<h4>Are you sure you want to delete this item?</h4>
		<br>
		<button class="cancel" (click)="cancel()">Cancel</button>
		<button (click)="confirmDelete()" style="margin-left: 10px;"
			class="confirm">Delete</button>
	</div>
	<div class="popupBack" *ngIf="this.confirmPopup"></div>
	<div class="content">
		<button class="breadcrumb" (click)="this.navigateBack()">&#11164;
			Back to Profile</button>
		<h2 *ngIf="!this.profileOwner()">Not Your Profile</h2>
		<div *ngIf="this.profileOwner()">
			<h1 id="manageTodo">Manage To-do List</h1>
			<button (click)="save()">Save Changes</button>
			<p *ngIf="!this.saved">
				<i>Make sure to hit save when you're done</i>
			</p>
			<p *ngIf="this.saved">
				<b>Your changes have been saved.</b>
			</p>
			<h2>Current Items</h2>
			<div *ngFor="let todo of todos">
				<hr>
				<h3>{{ todo.name }}</h3>
				<label class="dark" for="val" *ngIf="todo.type===1">Completion
					Value</label><label class="dark" for="val" *ngIf="todo.type===0">Completion
					%</label><br> <input (change)="onChange()" maxlength="24" [(ngModel)]="todo.value"
					type="text" id="tval" name="val" style="max-width: 40px;"><br>
				<label class="dark" for="max" *ngIf="todo.type===1">Completion
					Goal</label><br> <input (change)="onChange()" *ngIf="todo.type===1" maxlength="24"
					[(ngModel)]="todo.max" type="text" id="tval" name="max"
					style="max-width: 40px;"><br *ngIf="todo.type===1"><br *ngIf="todo.type===1">
				<button (click)="this.deleteItem(todo.id)">Delete</button>
			</div>
			<hr>
			<br>
			<div *ngIf="todos?.length === 0">
				<h3 style="margin: 20px 0px -7px 20px">No Items Yet</h3>
				<p style="margin-left: 20px">
					<i>Add an item below!</i>
				</p>
			</div>
			<br>
			<div class="panel">
				<h2 class="light">Add Item</h2>
				<label class="light" for="name">Name *</label><br> <input
					maxlength="24" [(ngModel)]="addName" type="text" id="tname"
					name="name" style="max-width: 400px;"><br> <br> <br>
				<label class="light" for="name">Completion Display Type *</label><br>
				<select [(ngModel)]="addType" id="ttype" name="type"
					(change)="this.typeChange()" style="max-width: 400px;"><option
						class="option" value="0">Percentage</option>
					<option value="1" class="option">Fraction</option></select><br>

				<div *ngIf="this.addType === '0'">
					<label class="light" for="valuep">% Completion *</label><br> <input
						maxlength="3" [(ngModel)]="addValue" type="text" id="tvaluep"
						name="valuep" style="max-width: 50px;"><br>
				</div>

				<div *ngIf="this.addType === '1'">
					<label class="light" for="value">Numerator *</label><br> <input
						maxlength="9" [(ngModel)]="addValue" type="text" id="tvalue"
						name="value" style="max-width: 100px;"><br> <label
						class="light" for="max">Denominator *</label><br> <input
						maxlength="9" [(ngModel)]="addMax" type="text" id="tmax"
						name="max" style="max-width: 100px;"><br>
				</div>
				<br> <label class="light" for="deadline">Deadline</label><br>
				<input type="date" id="deadline" name="deadline"
					[(ngModel)]="this.addDeadline" /> <br> <label class="light"
					for="deadlinename">Deadline Name</label><br> <input
					maxlength="24" [(ngModel)]="addDeadlinename" type="text"
					id="tdeadlinename" name="deadlinename" style="max-width: 100px;"><br>
				<label class="light" for="hastime">Specific Time</label><br> <input
					type="checkbox" [(ngModel)]="this.addHastime"><br> <br>
				<input *ngIf="this.addHastime" type="time" id="time" name="time"
					[(ngModel)]="this.addTime" /> <br> <br>

				<button (click)="this.addItem()">Add Item</button>
				<br> <br>

			</div>
			<br> <br>
		</div>
	</div>
</body>
</html>
