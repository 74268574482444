<html>
<div class="errors" *ngIf="this.error?.length !== 0">
	<p class="errors">{{ this.error }}</p>
</div>
<body>
	<app-haus-header userName="{{ this.auth.getName() }}"
		photoUrl="{{ this.auth.getPhotoUrl() }}"
		userId="{{ this.auth.getUserId() }}"></app-haus-header>
	<div class="content">
		<h1>Create JediHaus Group Invite</h1>
		<h4><i>Copy invite link and send to recipient for them to join this group.</i></h4>
		<textarea readonly style="width: 300px" rows="1" id="link" type="text" class="dark" *ngIf="this.inviteCode">{{ 'https://www.jedi.haus/invite/' + this.inviteCode }}</textarea><br>
		<button (click)="copyText()" *ngIf="this.inviteCode">Copy Link</button>
		<p class="dark" *ngIf="this.inviteCode.length === 0">Generating invite, please wait...</p>
	</div>
</body>
</html>
